'use client';

import React from 'react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { useParams, usePathname } from 'next/navigation';

import { Kbd } from '@zealy/design-system';
import { useSubscriptionStatus } from '@zealy/queries';

import Skeletons from '#components/Skeletons';

import { useFilteredQuestboard } from '../../_components/Board.hooks';
import { Module } from '../../_components/GridView/Module';
import { KeyType } from '../../../reviews/_components/Command';
import { AdminPreviewBanner } from '../../AdminPreviewBanner';

interface LayoutProps {
  children: React.ReactNode;
  params: {
    subdomain: string;
    moduleId: string;
    questId?: string;
  };
}

export function ModuleClientLayout({ children, params: { moduleId, subdomain } }: LayoutProps) {
  const t = useTranslations('questboard');
  const { data: subscriptionData } = useSubscriptionStatus();
  const pathname = usePathname();

  const { modules, isLoading } = useFilteredQuestboard();
  const data = modules.find(module => module.id === moduleId);
  const { questId } = useParams<{ questId?: string }>();

  const routesToHideModulePanel = ['/submissions'];
  const shouldHideModulePanel = Boolean(
    routesToHideModulePanel.find(route => pathname.endsWith(route)),
  );

  return (
    <div className="flex flex-col gap-100 w-full min-h-0 md:h-full h-fill overflow-hidden">
      <AdminPreviewBanner subdomain={subdomain} />

      <div className="flex min-h-0 flex-1 flex-row gap-100 h-full overflow-y-auto">
        {!shouldHideModulePanel && (
          <div
            className={clsx(
              'w-full lg:w-[450px] h-full relative bg-secondary rounded-md shrink-0',
              questId ? 'hidden lg:flex items-stretch' : '',
            )}
          >
            {data && (
              <Module
                category={data}
                isSubscriptionLimitReached={subscriptionData?.isLimitReached}
                fullHeight
              />
            )}

            <div className="absolute bottom-100 left-0 right-0 hidden xl:block">
              <p className="mx-auto text-center body-component-md text-tertiary bg-tertiary rounded-full px-200 py-50 w-fit">
                <Kbd className="mr-50">{KeyType.ArrowDown}</Kbd>
                <Kbd className="mr-100">{KeyType.ArrowUp}</Kbd>
                {t('to-navigate')}
              </p>
            </div>
            {isLoading && <Skeletons.QuestList totalOfSkeletons={6} />}
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
