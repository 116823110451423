'use client';

import { useLocalStorageValue } from '@react-hookz/web';
import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { useParams } from 'next/navigation';

import {
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@zealy/design-system';
import { GetReviewOutput } from '@zealy/schemas';

import { CommandProps, dispatchShortcut } from './Command';
import { ShortcutID } from './Command.const';
import { reviewListShortcuts, reviewShortcuts } from './CommandReview.const';

const listActions = [
  reviewListShortcuts[ShortcutID.REVIEW_SELECT_ALL](),
  reviewListShortcuts[ShortcutID.REVIEW_SELECT](),
  reviewListShortcuts[ShortcutID.REVIEW_SELECT_TOGGLE](),
  reviewListShortcuts[ShortcutID.REVIEW_PREV](),
  reviewListShortcuts[ShortcutID.REVIEW_NEXT](),
];

export const CommandReview = (props: CommandProps<GetReviewOutput>) => {
  const t = useTranslations();
  const { claimId } = useParams<{ claimId: string }>();

  const { value: contextIsOpen } = useLocalStorageValue<boolean>('reviews:panel');

  const reviewActions = useMemo(
    () => [
      reviewShortcuts[ShortcutID.REVIEW_TOGGLE_CONTEXT](contextIsOpen),
      reviewShortcuts[ShortcutID.REVIEW_COMMENT](''),
      reviewShortcuts[ShortcutID.COMMAND_REVIEW_COMMENT](props.payload),
      reviewShortcuts[ShortcutID.REVIEW_SUCCESS](),
      reviewShortcuts[ShortcutID.REVIEW_FAIL](),
      reviewShortcuts[ShortcutID.REVIEW_STAR](),
      reviewShortcuts[ShortcutID.REVIEW_FLAG](),
      reviewShortcuts[ShortcutID.REVIEW_BONUS](),
    ],
    [props.payload, contextIsOpen],
  );

  const handleSelect = (action: (typeof reviewActions)[number] | (typeof listActions)[number]) => {
    dispatchShortcut({
      id: action.id,
      payload: action.payload,
    });
  };

  return (
    <>
      <CommandInput placeholder={t('reviews.command.placeholder')} />
      <CommandList>
        <CommandEmpty>{t('reviews.command.no-results')}</CommandEmpty>
        <CommandGroup
          heading="Suggested"
          className={clsx({
            // Want to hide list actions when list is not visible
            'hidden lg:block': !!claimId,
          })}
        >
          {listActions.map(action => (
            <CommandItem
              icon={action.icon({})}
              shortcut={action.keys?.join('')}
              key={action.label}
              onSelect={() => handleSelect(action)}
            >
              {t(action.label)}
            </CommandItem>
          ))}
        </CommandGroup>
        {claimId && (
          <CommandGroup heading="Review">
            {reviewActions.map(action => (
              <CommandItem
                icon={action.icon({})}
                shortcut={action.keys?.join('')}
                key={action.label}
                onSelect={() => handleSelect(action)}
              >
                {t(action.label)}
              </CommandItem>
            ))}
          </CommandGroup>
        )}
        <CommandSeparator />
      </CommandList>
    </>
  );
};
