'use client';

import React from 'react';
import { useTranslations } from 'next-intl';

import {
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@zealy/design-system';
import { useReviewComments } from '@zealy/queries';
import { GetReviewOutput } from '@zealy/schemas';

import { CommandProps, dispatchShortcut } from './Command';
import { ShortcutID } from './Command.const';

const DEFAULT_COMMENTS = [
  // Positive Feedback
  'Great job! You really nailed it.',
  "Awesome work! It's clear you put in a lot of effort.",
  'You got it spot on! Keep up the great work.',

  // Areas for Improvement
  "Not quite there yet, but you're close! Let's tweak it a bit.",
  'Good effort! Just needs a bit more attention to the instructions.',
  'Needs closer alignment with instructions. Please review them to avoid any restrictions on future claims.',
];

export const CommandReviewComment = (props: CommandProps<GetReviewOutput>) => {
  const t = useTranslations('reviews');
  const recent = useReviewComments(props.payload?.quest?.id ?? '');

  const handleSelect = (comment: string) => {
    dispatchShortcut({
      id: ShortcutID.REVIEW_COMMENT,
      payload: comment,
    });
  };

  if (recent.isLoading) return null;

  return (
    <>
      <CommandInput placeholder={t('command.placeholder')} autoFocus />
      <CommandList>
        <CommandEmpty>{t('command.no-results')}</CommandEmpty>
        {!!recent.data?.length && (
          <CommandGroup heading={t('command.recent-comments')}>
            {recent.data.map(comment => (
              <CommandItem key={comment} onSelect={() => handleSelect(comment)}>
                {comment}
              </CommandItem>
            ))}
          </CommandGroup>
        )}
        <CommandSeparator />
        <CommandGroup heading={t('command.suggestions')}>
          {DEFAULT_COMMENTS.map(comment => (
            <CommandItem key={comment} onSelect={() => handleSelect(comment)}>
              {comment}
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </>
  );
};
