'use client';

import React, { useEffect, useRef, useState } from 'react';

import { CommandDialog } from '@zealy/design-system';

import { useKeyboardShortcut } from '#hooks/useKeyboardShortcut';

import type { Action } from './CommandReview.const';
import { ShortcutID } from './Command.const';
import { CommandReview } from './CommandReview';
import { CommandReviewComment } from './CommandReviewComment';

const commands = {
  [ShortcutID.COMMAND_REVIEW]: CommandReview,
  [ShortcutID.COMMAND_REVIEW_COMMENT]: CommandReviewComment,
} as const;

export interface Shortcut {
  id: ShortcutID;
  payload?: any;
}

export function dispatchShortcut(shortcut: Shortcut, event?: Event) {
  const options = { detail: { shortcut, event } };

  const shortcutEvent = new CustomEvent('shortcut', options);

  window.dispatchEvent(shortcutEvent);
}

function useCommand() {
  const [open, setOpen] = useState<ShortcutID>();
  const [payload, setPayload] = useState<any>();

  return {
    setOpen: (id?: ShortcutID, pl?: any) => {
      setOpen(id);
      setPayload(pl);
    },
    open,
    payload,
  };
}

export function useShortcut(cb: (shortcut: Shortcut) => Promise<void>) {
  const callbackRef = useRef(cb);
  callbackRef.current = cb;

  useEffect(() => {
    const handler = (e: Event) => {
      const event = e as CustomEvent<{
        shortcut: Shortcut;
        event?: Event;
      }>;

      if (event.detail?.shortcut) {
        callbackRef.current(event.detail.shortcut).catch(console.error);
      }
    };
    window.addEventListener('shortcut', handler);
    return () => {
      window.removeEventListener('shortcut', handler);
    };
  }, []);
}

export function extractActionShortcuts(actions: Action[]) {
  return actions.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.events.join('+')]: () =>
        dispatchShortcut({
          id: curr.id,
          payload: curr.payload,
        }),
    }),
    {},
  );
}

export interface CommandProps<T> {
  command?: ShortcutID;
  payload?: T;
}

export function Command(props: CommandProps<any>) {
  const command = useCommand();

  const CommandDynamic = command.open
    ? commands[command.open as ShortcutID.COMMAND_REVIEW | ShortcutID.COMMAND_REVIEW_COMMENT]
    : null;

  useShortcut(async (shortcut: Shortcut) => {
    switch (shortcut.id) {
      case ShortcutID.COMMAND_REVIEW: {
        command.setOpen(ShortcutID.COMMAND_REVIEW, shortcut.payload);
        break;
      }
      case ShortcutID.COMMAND_REVIEW_COMMENT: {
        command.setOpen(ShortcutID.COMMAND_REVIEW_COMMENT, shortcut.payload);
        break;
      }
      case ShortcutID.CLOSE_COMMAND: {
        command.setOpen();
        break;
      }
    }
  });

  useKeyboardShortcut({
    'cmd+k': () => command.setOpen(ShortcutID.COMMAND_REVIEW),
  });

  return (
    <CommandDialog open={!!command.open} onOpenChange={open => !open && command.setOpen(undefined)}>
      {CommandDynamic && <CommandDynamic command={command.open} payload={command.payload} />}
    </CommandDialog>
  );
}
