import { isMacintosh } from '#utils/isMackintosh';

export enum ShortcutID {
  COMMAND_REVIEW = 1,
  COMMAND_REVIEW_COMMENT,
  CLOSE_COMMAND,
  REVIEW_COMMENT,
  REVIEW,
  REVIEW_SUCCESS,
  REVIEW_FAIL,
  REVIEW_STAR,
  REVIEW_FLAG,
  REVIEW_BONUS,
  REVIEW_TOGGLE_CONTEXT,
  REVIEW_PREV,
  REVIEW_NEXT,
  REVIEW_SELECT,
  REVIEW_SELECT_CLEAR,
  REVIEW_SELECT_ALL,
  REVIEW_SELECT_TOGGLE,
  REVIEW_ADD_FILTER,
  REVIEW_CLEAR_FILTERS,
}

export const KeyType = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
  G: 'G',
  H: 'H',
  I: 'I',
  J: 'J',
  K: 'K',
  L: 'L',
  M: 'M',
  N: 'N',
  O: 'O',
  P: 'P',
  Q: 'Q',
  R: 'R',
  S: 'S',
  T: 'T',
  U: 'U',
  V: 'V',
  W: 'W',
  X: 'X',
  Y: 'Y',
  Z: 'Z',
  '0': '0',
  '1': '1',
  '2': '2',
  '3': '3',
  '4': '4',
  '5': '5',
  '6': '6',
  '7': '7',
  '8': '8',
  '9': '9',
  '-': '-',
  ArrowUp: '↑',
  ArrowDown: '↓',
  ArrowLeft: '←',
  ArrowRight: '→',
  Shift: '⇧',
  Cmd: isMacintosh() ? '⌘' : '^', // On Windows/Linux, this could be represented as Ctrl
  Ctrl: 'Ctrl',
  Alt: '⎇',
  Escape: 'Esc',
  F1: 'F1',
  F2: 'F2',
  F3: 'F3',
  F4: 'F4',
  F5: 'F5',
  F6: 'F6',
  F7: 'F7',
  F8: 'F8',
  F9: 'F9',
  F10: 'F10',
  F11: 'F11',
  F12: 'F12',
  '.': '.',
  ',': ',',
  '[': '[',
  ']': ']',
  '#': '#',
};
