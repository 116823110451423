import type { GetReviewOutput } from '@zealy/schemas';
import {
  ArrowBottomLine,
  ArrowTopLine,
  BookmarkFilled,
  CheckCircle2Filled,
  CheckRecFilled,
  Comment2Filled,
  CrossCircleFilled,
  CrossLine,
  FilterLine,
  Flag2Filled,
  GiftFilled,
  Grid05Line,
  LightningFilled,
  ListUnordered4Line,
  ListUnordered5Line,
  StarFilled,
} from '@zealy/icons';

import { KeyType, ShortcutID } from './Command.const';

export interface Action {
  id: ShortcutID;
  keys: readonly string[];
  events: readonly string[];
  label: string;
  icon: typeof LightningFilled;
  keywords: readonly string[];
  payload?: any;
}

export const reviewShortcuts = {
  [ShortcutID.COMMAND_REVIEW]: (claim?: GetReviewOutput) => {
    return {
      id: ShortcutID.COMMAND_REVIEW,
      keys: [KeyType['Cmd'], KeyType['K']],
      events: ['Cmd', 'K'],
      label: 'reviews.form.show-shortcuts',
      icon: LightningFilled,
      keywords: ['review'],
      payload: claim,
    } as const;
  },
  [ShortcutID.COMMAND_REVIEW_COMMENT]: (claim?: GetReviewOutput) => {
    return {
      id: ShortcutID.COMMAND_REVIEW_COMMENT,
      keys: [KeyType['#']],
      events: ['#'],
      label: 'reviews.form.recent-comments',
      icon: BookmarkFilled,
      keywords: ['review'],
      payload: claim,
    } as const;
  },
  [ShortcutID.REVIEW_COMMENT]: (comment: string) => {
    return {
      id: ShortcutID.REVIEW_COMMENT,
      keys: [KeyType['C']],
      events: ['C'],
      label: 'reviews.form.comment',
      icon: Comment2Filled,
      keywords: ['review'],
      payload: comment,
    } as const;
  },
  [ShortcutID.REVIEW_SUCCESS]: () => {
    return {
      id: ShortcutID.REVIEW,
      keys: [KeyType['S']],
      events: ['S'],
      label: 'reviews.form.review.success',
      icon: CheckCircle2Filled,
      keywords: ['review'],
      payload: { status: 'success' },
    } as const;
  },
  [ShortcutID.REVIEW_FAIL]: () => {
    return {
      id: ShortcutID.REVIEW,
      keys: [KeyType['F']],
      events: ['F'],
      label: 'reviews.form.review.fail',
      icon: CrossCircleFilled,
      keywords: ['review'],
      payload: { status: 'fail' },
    } as const;
  },
  [ShortcutID.REVIEW_STAR]: () => {
    return {
      id: ShortcutID.REVIEW,
      keys: [KeyType['Shift'], KeyType['S']],
      events: ['shift', 'S'],
      label: 'reviews.form.review.star',
      icon: StarFilled,
      keywords: ['review'],
      payload: { mark: 'star', status: 'success' },
    } as const;
  },
  [ShortcutID.REVIEW_FLAG]: () => {
    return {
      id: ShortcutID.REVIEW,
      keys: [KeyType['Shift'], KeyType['F']],
      events: ['shift', 'F'],
      label: 'reviews.form.review.flag',
      icon: Flag2Filled,
      keywords: ['review'],
      payload: { mark: 'flag', status: 'fail' },
    } as const;
  },
  [ShortcutID.REVIEW_BONUS]: () => {
    return {
      id: ShortcutID.REVIEW_BONUS,
      keys: [KeyType['Shift'], KeyType['B']],
      events: ['shift', 'B'],
      label: 'reviews.form.bonus',
      icon: GiftFilled,
      keywords: ['review'],
      payload: undefined,
    } as const;
  },
  [ShortcutID.REVIEW_TOGGLE_CONTEXT]: (isOpen?: boolean) => {
    return {
      id: ShortcutID.REVIEW_TOGGLE_CONTEXT,
      keys: [KeyType['Shift'], KeyType['C']],
      events: ['shift', 'C'],
      label: `reviews.context.${!isOpen ? 'open' : 'close'}`,
      icon: Grid05Line,
      keywords: ['review'],
      payload: undefined,
    } as const;
  },
};

export const reviewListShortcuts = {
  [ShortcutID.REVIEW_ADD_FILTER]: (select?: true) => {
    return {
      id: ShortcutID.REVIEW_ADD_FILTER,
      keys: [KeyType['Alt'], KeyType['F']],
      events: ['Alt', 'F'],
      label: 'reviews.filter.add',
      icon: FilterLine,
      keywords: ['review'],
      payload: select,
    } as const;
  },
  [ShortcutID.REVIEW_CLEAR_FILTERS]: () => {
    return {
      id: ShortcutID.REVIEW_CLEAR_FILTERS,
      keys: [KeyType['Shift'], KeyType['Alt'], KeyType['F']],
      events: ['Shift', 'Alt', 'F'],
      label: 'reviews.filter.clear',
      icon: CrossLine,
      keywords: ['review'],
      payload: undefined,
    } as const;
  },
  [ShortcutID.REVIEW_PREV]: (select?: true) => {
    return {
      id: ShortcutID.REVIEW_PREV,
      keys: [KeyType['ArrowUp']],
      events: ['ArrowUp'],
      label: 'reviews.prev',
      icon: ArrowTopLine,
      keywords: ['review'],
      payload: select,
    } as const;
  },
  [ShortcutID.REVIEW_NEXT]: (select?: true) => {
    return {
      id: ShortcutID.REVIEW_NEXT,
      keys: [KeyType['ArrowDown']],
      events: ['ArrowDown'],
      label: 'reviews.next',
      icon: ArrowBottomLine,
      keywords: ['review'],
      payload: select,
    } as const;
  },
  [ShortcutID.REVIEW_SELECT]: (prev?: true) => {
    return {
      id: prev ? ShortcutID.REVIEW_PREV : ShortcutID.REVIEW_NEXT,
      keys: prev
        ? [KeyType['Shift'], KeyType['ArrowUp']]
        : [KeyType['Shift'], KeyType['ArrowDown']],
      events: prev ? ['Shift', 'ArrowUp'] : ['Shift', 'ArrowDown'],
      label: 'reviews.select-next',
      icon: CheckRecFilled,
      keywords: ['review'],
      payload: true,
    } as const;
  },
  [ShortcutID.REVIEW_SELECT_ALL]: () => {
    return {
      id: ShortcutID.REVIEW_SELECT_ALL,
      keys: [KeyType['Cmd'], KeyType['A']],
      events: ['Cmd', 'A'],
      label: 'reviews.select-all',
      icon: ListUnordered4Line,
      keywords: ['review'],
      payload: undefined,
    } as const;
  },
  [ShortcutID.REVIEW_SELECT_CLEAR]: () => {
    return {
      id: ShortcutID.REVIEW_SELECT_CLEAR,
      keys: [KeyType['Escape']],
      events: ['Escape'],
      label: 'reviews.select-clear',
      icon: ListUnordered5Line,
      keywords: ['review'],
      payload: undefined,
    } as const;
  },
  [ShortcutID.REVIEW_SELECT_TOGGLE]: () => {
    return {
      id: ShortcutID.REVIEW_SELECT_CLEAR,
      keys: [KeyType['Alt']],
      events: ['Alt'],
      label: 'reviews.select-toggle',
      icon: CheckRecFilled,
      keywords: ['review'],
      payload: undefined,
    } as const;
  },
};
